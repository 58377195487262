
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  name: "ErrorRefresh",
  components: {},
})
export default class ErrorRefresh extends Vue {
  @Action("UnSyncUser", { namespace: "isSynced" })
  UnSyncUser!: (isSynced: boolean) => Promise<any>;
  @Action("clearMenuConfiguration", { namespace: "application" })
  clearMenuConfiguration!: () => void;
  @Action("clearViewContext", { namespace: "application" })
  clearViewContext!: () => void;
  mounted() {
    this.clearMenuConfiguration();
    this.clearViewContext();
    this.UnSyncUser(false);
    this.$jfModal.closeAll();
    this.$jfNotification.clearAll();
  }
}
